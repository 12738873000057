import Vue from 'vue';
import Router from 'vue-router';
import store from '@/core/services/store';

Vue.use(Router);
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    redirect: '/ml-dashboard',
    component: () => import('@/view/layout/Layout'),
    children: [
      {
        path: '/ml-dashboard',
        name: 'ml-dashboard',
        component: () => import('@/view/pages/ml/Dashboard.vue')
      },
      {
        path: '/ml-companies',
        name: 'ml-companies',
        component: () => import('@/view/pages/ml/Companies.vue')
      },
      {
        path: '/ml-system',
        name: 'ml-system',
        component: () => import('@/view/pages/ml/System.vue')
      },
      {
        path: '/ml-apikeys',
        name: 'ml-apikeys',
        component: () => import('@/view/pages/ml/APIKeys.vue')
      },
      {
        path: '/',
        component: () => import('@/view/layout/Vuetify.vue'),
        children: [
          {
            path: '/ml-users',
            name: 'ml-users',
            component: () => import('@/view/pages/ml/Users.vue')
          },
          {
            path: '/ml-users/:id',
            name: 'ml-users',
            component: () => import('@/view/pages/ml/Users.vue')
          },
          {
            path: '/ml-billing',
            name: 'ml-billing',
            component: () => import('@/view/pages/ml/Billing.vue')
          },
          {
            path: '/ml-book',
            name: 'ml-book',
            component: () => import('@/view/pages/ml/Book.vue')
          },
        ]
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/view/pages/ml/auth/Auth.vue'),
    children: [
      {
        name: 'ml-login',
        path: '/ml-login',
        component: () => import('@/view/pages/ml/auth/Login.vue')
      },
      {
        name: 'ml-forget',
        path: '/ml-forget',
        component: () => import('@/view/pages/ml/auth/Forget.vue')
      },
      {
        name: 'ml-reset',
        path: '/ml-reset/:token',
        component: () => import('@/view/pages/ml/auth/Reset.vue')
      }
    ]
  },  
  {
    path: '*',
    redirect: '/404'
  },
  {
    // the 404 route, when none of the above matches
    path: '/404',
    name: '404',
    component: () => import('@/view/pages/Error.vue')
  },
];

const router = new Router({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});


router.beforeEach((to, from, next) => {
  
  const isAuthenticated = store.getters['isAuthenticated'];

  const dest = to.path.replace("/", "");
  console.log('isAuthenticated: ' + isAuthenticated);

  if (!isAuthenticated && dest !== 'ml-login') {
    router.push({name: 'ml-login'});

    return;
  }

  return next();
});

export default router;  
